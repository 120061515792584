html {
  height: 100%;
  scroll-behavior: smooth;
  margin: 0 0;
}

:root {
  --lav: #a4adcb;
  --grey: #707070;
  --dkgrey: #4d4d4d;
  --penk: #dc9ce4;
  --bloo: #b7c8f9;
  --teel: #acebd1;
  --halfgrey: rgba(70, 70, 70, 0.5);
}

body {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  justify-content: center;
  background: white;
  display: flex;
  flex-direction: column;
}
