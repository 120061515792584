.Branch {
  width: 100%;
  min-height: 10%;
}
.AppleBranch {
  min-height: 75px;
  margin-top: -100px;
  margin-left: 230px;
  display: flex;
}
.Apple {
  font-size: xx-large;
  font-weight: bolder;
  display: flex;
  min-height: 75px;
  min-width: 75px;
  width: 50%;
}
.AppleLet {
  font-size: xx-large;
  color: #fff;
  font-weight: bolder;
  text-align: center;
  position: relative;
  margin-left: -75px;
  margin-top: -60px;
}
.Basket {
  background-image: url(./imgs/Basket.png);
}

.animation {
  min-width: 30%;
  float: right;
  position: sticky;
  margin-right: -20px;
  text-align: right;
  margin-bottom: -20rem;
}

.animation img {
  width: 40%;
}

h1 {
  font-size: 5rem !important;
  font-weight: bolder;
  /* margin-top: -5rem; */
}

h2 {
  margin-top: 5rem !important;
}

.flex-row-justify {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-row-evenly {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.bar {
  margin: 1rem auto;
  padding: 1rem 1rem;
  background-color: var(--teel);
  border-radius: 10px;
}

.links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.iconDivs {
  padding: 15px;
  justify-content: space-evenly;
}

.downloadRes {
  padding: 5px;
  align-content: flex-end;
  text-align: center;
}

.intro {
  max-width: 75%;
}
@media (max-width: 767.98px) {
  .intro {
    max-width: 100%;
  }
}
