footer {
    position: sticky;
    bottom: 0;
    min-height: 5rem;
    background-color: var(--lav);
    min-width: 100%;
    color: white;
    margin-top: 5rem;
    padding-top: 1rem;
    line-height: 1rem;
    text-align: center;
    flex-shrink: 0;
  }

