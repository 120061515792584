.learn {
  min-width: 6rem;
  min-height: 6rem;
  border: var(--grey) solid 1px;
  border-radius: 15px;
  color: var(--grey);
  background-color: white;
  padding: 1rem 0rem 0rem 0rem;
  margin: 0.5rem;
  text-align: center;
  line-height: 1;
  flex-direction: row;

  /* flex: 1 0 10%; */
  font-size: 0.8em;
}
