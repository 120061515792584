.logo {
  width: 20%;
  padding: 14px 10px 8px 10px;
  margin: 0px 10px 0px 10px;
  background-color: #fff !important;
  border: 1px solid #707070;
}

header {
  background-color: #a4adcb !important;
  text-align: left;
  line-height: 1.5;
}

header a {
  color: white !important;
}
.nav-item a:hover {
  color: var(--penk) !important;
  background-color: var(--halfgrey);
  border-radius: 10px;
}

.navbar {
  width: 100%;
}